import { ThemeProps } from '@sport1/news-styleguide/Types'
import React, { FC } from 'react'
import useTheme from '@sport1/react-elements/useTheme'

const year = new Date().getFullYear()

const FooterCopyright: FC = () => {
    const { themeName } = (useTheme() as ThemeProps['theme']) || {}

    return (
        <div className="container">
            <p
                className={`text-12 leading-none md:text-left ${
                    themeName === 'dark' ? 'text-white' : 'text-black'
                }`}
            >
                Copyright © {year} Sport1 GmbH. Alle Rechte vorbehalten.
            </p>
        </div>
    )
}

export default FooterCopyright
