import React, { FC } from 'react'
import useTheme from '@sport1/react-elements/useTheme'
import { ThemeProps } from '@sport1/news-styleguide/Types'
import { FooterSeoNavigationItem } from './types/FooterSeoNavigationItem'

type Props = {
    link: FooterSeoNavigationItem
}

const FooterSeoNavigationLink: FC<Props> = ({ link }) => {
    const { themeName } = (useTheme() as ThemeProps['theme']) || {}

    return (
        <a
            href={link.targetUrl}
            target={link.target}
            rel={link.rel}
            className={`block my-12 hover:underline leading-[20px] ${
                themeName === 'dark' ? 'text-white' : 'text-black'
            }`}
        >
            {link.title}
        </a>
    )
}

export default FooterSeoNavigationLink
