import React, { FC } from 'react'
import Sport1Logo from '@sport1/news-styleguide/Sport1Logo'
import useTheme from '@sport1/react-elements/useTheme'
import { ThemeProps } from '@sport1/news-styleguide/Types'

const FooterLogo: FC = () => {
    const { themeName } = (useTheme() as ThemeProps['theme']) || {}

    return (
        <div className="container">
            <Sport1Logo
                href="/"
                type={themeName === 'dark' ? 'OnDark' : 'OnLight'}
                width={112}
                testID="footer-banner-sport1-logo"
            />
        </div>
    )
}

export default FooterLogo
