import React, { FC } from 'react'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import { Trademark } from './types/Trademark'
import FooterTrademark from './FooterTrademark'

const trademarks: Trademark[] = [
    {
        title: 'CrowdTransfer',
        iconUrl: 'https://reshape.sport1.de/c/t/0381bd9b-d489-4d50-8eda-9e2d7a67689d/0x35',
        targetUrl: 'https://crowdtransfer.io/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Car4Sports',
        iconUrl: 'https://reshape.sport1.de/c/t/bbc42676-b83b-4aa6-a7d5-daff757e28b2/0x35',
        targetUrl: 'https://car4sports.de/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Muuvr',
        iconUrl: 'https://reshape.sport1.de/c/t/e66f854a-ca77-4b1b-8ff7-4393e4377035/0x35',
        targetUrl: 'https://muuvr.io/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Jackpot 50',
        iconUrl: 'https://reshape.sport1.de/c/t/979fc4f7-8922-4bff-b7d2-44b55f224313/0x35',
        targetUrl: 'https://www.jackpot50.de/de',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Clanq',
        iconUrl: 'https://reshape.sport1.de/c/t/c985945b-5464-495c-b90e-8aaa47091150/0x35',
        targetUrl: 'https://www.clanq.de/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'Racemates',
        iconUrl: 'https://reshape.sport1.de/c/t/b1790037-f61b-4ec1-a80a-9b812e55eda8/0x35',
        targetUrl: 'https://racemates-nft.com/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
    {
        title: 'TigerSpin',
        iconUrl: 'https://reshape.sport1.de/c/t/5ededbec-ba6b-4999-b0c5-35250e551a3d/0x35',
        targetUrl: 'https://www.tigerspin.de/',
        target: '_blank',
        rel: 'noreferrer noopener nofollow',
        width: 0,
        height: 35,
    },
]

const FooterTrademarkList: FC = () => {
    return (
        <FlexingContainer className="justify-between gap-[20px]" horizontal>
            {trademarks.map((trademark, index) => (
                <FooterTrademark trademark={trademark} key={`${trademark.title}-${index}`} />
            ))}
        </FlexingContainer>
    )
}

export default FooterTrademarkList
