import React, { FC, memo } from 'react'
import Image from '@sport1/news-styleguide/Image'
import colorPattern from '@sport1/news-styleguide/colorPattern'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import useTheme from '@sport1/react-elements/useTheme'
import { ThemeProps } from '@sport1/news-styleguide/Types'
import { Trademark } from './types/Trademark'

type Props = {
    trademark: Trademark
}

const FooterTrademark: FC<Props> = ({ trademark }) => {
    const { themeName } = (useTheme() as ThemeProps['theme']) || {}

    const image = (
        <Image
            size="S"
            key={trademark.iconUrl}
            imageUrl={trademark.iconUrl}
            alt={trademark.title}
            aspectRatio={trademark.width / trademark.height}
            resizeMode="contain"
        />
    )

    return (
        <NonFlexingContainer
            className="list-none"
            width={['52px', '99px', '145px']}
            backgroundColor={themeName === 'dark' ? colorPattern.moon : undefined}
            center
            paddingX={['spacing-1', 'spacing-4', 'spacing-6']}
            paddingY={['spacing-1', 'spacing-3', 'spacing-5']}
        >
            {trademark.targetUrl ? (
                <a href={trademark.targetUrl} target={trademark.target} rel={trademark.rel}>
                    {image}
                </a>
            ) : (
                image
            )}
        </NonFlexingContainer>
    )
}

export default memo(FooterTrademark)
