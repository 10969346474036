import React, { FC } from 'react'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import useTheme from '@sport1/react-elements/useTheme'
import { ThemeProps } from '@sport1/news-styleguide/Types'
import FooterLogo from './components/FooterLogo/FooterLogo'
import FooterTrademarkList from './components/FooterTrademarkList/FooterTrademarkList'
import FooterNavigation from './components/FooterNavigation/FooterNavigation'
import FooterCopyright from './components/FooterCopyright/FooterCopyright'
import FooterSeoNavigation from './components/FooterSeoNavigation/FooterSeoNavigation'

type Props = {
    type?: 'TV'
}
const Footer: FC<Props> = ({ type }) => {
    const { themeName } = (useTheme() as ThemeProps['theme']) || {}
    return (
        <footer className={`${themeName === 'dark' ? 'bg-[#1E1E1E]' : 'bg-white'} px-[24px] `}>
            <FlexingContainer
                maxWidth={1040}
                marginX="auto"
                className="gap-[24px] 2xl:gap-[36px] pt-[12px] pb-[24px] sm:pt-[24px] 2xl:pt-[36px] 2xl:pb-[36px]"
            >
                <FooterLogo />
                <FooterSeoNavigation />
                <FooterTrademarkList />
                <FooterNavigation type={type} />
                <FooterCopyright />
            </FlexingContainer>
        </footer>
    )
}

export default Footer
