import React, { FC } from 'react'
import useTheme from '@sport1/react-elements/useTheme'
import { ThemeProps } from '@sport1/news-styleguide/Types'
import { FooterNavigationItem } from './types/FooterNavigationItem'
import { openPrivacyManager } from '@/components/ThirdParty/SourcePoint'

type Props = {
    link: FooterNavigationItem
}

const FooterNavigationLink: FC<Props> = ({ link }) => {
    const { themeName } = (useTheme() as ThemeProps['theme']) || {}

    if (link.targetUrl === '') return null

    if (link.targetUrl?.includes('sport1://privacy')) {
        return (
            <li
                className={`mb-12 text-base leading-tight pr-6 list-none ${
                    themeName === 'dark' ? 'text-white' : 'text-black'
                }`}
            >
                <a className="hover:underline cursor-pointer" onClick={() => openPrivacyManager()}>
                    {link.title}
                </a>
            </li>
        )
    }

    return (
        <li
            className={`mb-12 text-base leading-tight pr-6 list-none ${
                themeName === 'dark' ? 'text-white' : 'text-black'
            }`}
        >
            <a
                href={link.targetUrl}
                target={link.target}
                rel={link.rel}
                className="hover:underline"
            >
                {link.title}
            </a>
        </li>
    )
}

export default FooterNavigationLink
